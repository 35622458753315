import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import SeasonYear from '../../components/SeasonYear';
import UtahOperaImage from '../../assets/images/publication-banners/utah-opera.jpg';

export const query = graphql`
  query OperaQuery {
    allUtahoperaCsv {
      nodes {
        CD
        FR
        Quantity
        RUNNING_DATES
        SHOW
        Opera
        Image
        Image2
      }
    }
  }
`;

export default function UtahOpera({ data }) {
  const operaNodes = data.allUtahoperaCsv.nodes;
  // console.log(balletNodes);

  const imageRef = '/season-images/opera/';
  // append the image reference with the path to the image via the static folder

  return (
    <Layout>
      <Helmet>
        <title>Utah Opera - Mills Publishing Inc.</title>
        <meta
          name="description"
          content="Advertising and event information for Utah Opera’s season playbills. Please contact Mills Publishing for availability and rates."
        />
      </Helmet>

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>Utah Opera</h1>
            </header>
            <span className="image main">
              <img src={UtahOperaImage} alt="" />
            </span>
            <p className="box">
              A unique advertising opportunity awaits those who select Utah
              Opera playbills. Your brand will be exposed to an audience that
              enjoys an affluent lifestyle, as well as the finest products and
              services.
            </p>
            <ul className="actions">
              <li>
                <a href="https://utahopera.org/" className="button special">
                  <i className="icon fa-globe"> </i>Utah Opera Website
                </a>
              </li>
            </ul>
            <h2>
              Utah Opera <SeasonYear /> Season Information
            </h2>
            <p>
              <em>
                Dates are subject to change. Please contact us if you have any
                questions.
              </em>
            </p>
            <div className="grid-wrapper">
              {/* Calls the season json, accesses the ecclesSeason array, and maps/creates a new array */}
              {operaNodes.map((node) => (
                <div className="col-4 box" key={node.id}>
                  <img
                    style={{ width: 100 + '%' }}
                    src={imageRef + node.Image2}
                  />
                  <img
                    style={{ width: 100 + '%' }}
                    src={imageRef + node.Image}
                  />
                  <h3>{node.SHOW}</h3>
                  <h4>{node.Opera}</h4>
                  <strong>{node.RUNNING_DATES}</strong> <br />
                  <br />
                  <h5>Advertising Information</h5>
                  <section className="box">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            File Ready Deadline: <br />
                            {node.FR}
                          </td>
                        </tr>
                        {/* <tr>
                          <td>Advertising Exposure: {node.exposure}</td>
                        </tr> */}
                        <tr>
                          <td>Print Quantity: {node.Quantity}</td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
